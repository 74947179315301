import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import Router from 'vue-router';
import CalculatorForm from '../views/CalculatorForm.vue';
import HowTo from '../views/HowTo.vue';
import MethodSummary from '../views/MethodSummary.vue';
import MontrealMap from '../components/MontrealMap.vue';
import ShowAlpha from '../views/ShowAlpha.vue';
import RealEstateGrid from '../views/AlphaPolyGrid.vue';
import VueSimpleAlert from "vue-simple-alert";
import LogIn from '../views/LogIn.vue';
import properties from '../store/modules/properties'

Vue.use(Router);
Vue.use(VueSimpleAlert);
Vue.use(Vuex);


// const debug = true;
// const debug = false;
const requiresAuth = false;
const debug = process.env.VUE_APP_DEBUG === 'true' || false;
const api_host = debug ? 'http://127.0.0.1:5000/' : 'https://alpha.realestatealpha.ai/';
axios.defaults.baseURL = api_host;

// Vuex store
const store = new Vuex.Store({
  modules: {
    properties
  },
  state: {
    // isAuthenticated: true,
    // isAuthenticated: false,
    user: null
  },
  mutations: {
    setAuth(state, auth) {
      state.isAuthenticated = auth;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/api/login', credentials);
        console.log('Login response:', response.data);
        if (response.data.user) {
          commit('setUser', response.data.user);
          commit('setAuth', true);
          console.log("Login response " + response.data);
          console.log('Login response headers:', response.headers);
          // await dispatch('checkSession');
          return true;
        }
        return false;
      } catch (error) {
        console.error('Login error:', error);
        return false;
      }
    },
    async logout({ commit }) {
      try {
        await axios.post(api_host + 'api/logout', {}, { withCredentials: true });
        commit('setAuth', false);
        commit('setUser', null);
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    async checkAuth({ commit }) {
      try {
        // const response = await axios.get(api_host + 'api/check_session');
        const response = await axios.get(api_host + 'api/check_session', { withCredentials: true });
        console.log('Check session response:', response.data);
        commit('setAuth', response.data.logged_in);
        if (response.data.logged_in) {
          commit('setUser', response.data.user);
        }
      } catch (error) {
        console.error('Check session error:', error);
        commit('setAuth', false);
        commit('setUser', null);
      }
    },
    async checkSession({ commit }) {
      try {
        const response = await axios.get('/api/check_session');
        console.log('Check session response:', response.data);
        commit('setAuth', response.data.logged_in);
        commit('setUser', response.data.user);
        return response.data.logged_in;
      } catch (error) {
        console.error('Check session error:', error);
        commit('setAuth', false);
        commit('setUser', null);
        return false;
      }
    },
    
    async fetchAlpha(_, {formData}) {
      try {
        console.log("fetchAlpha formData:", formData);
        const response = await axios.post(api_host + 'api/compute_alpha', formData, {
          headers: { 'Content-Type': 'application/json' },
        });
  
        if (response.data.status === 'success') {
          return { response: response, message: "Request successful."};
        } else {
          return { response: response, message: "Insufficient market data to compute alpha." };
        }
      } catch (error) {
        console.error("Error in fetchAlpha:", error);
        return { message: "Server error. Please try again." };
      }
    },
    async parse_link(_, {url}) {
      try {
        const response = await axios.post(api_host + 'api/parse_link', { "url":url })
        return {status: 'Success', response : response};
      } catch (error) {
        return {status: error};
      }
    },
  }
});

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'LogIn',
      component: LogIn
    },
    {
      path: '/howto',
      name: 'HowTo',
      component: HowTo,
      meta: { requiresAuth: requiresAuth}
    },
    {
      path: '/summary',
      name: 'MethodSummary',
      component: MethodSummary,
      meta: { requiresAuth: requiresAuth}
    },
    {
      path: '/montrealmap',
      name: 'MontrealMap',
      component: MontrealMap,
      meta: { requiresAuth: requiresAuth}
    },
    {
      path: '/showAlpha',
      name: 'ShowAlpha',
      component: ShowAlpha,
      props: true,
      meta: { requiresAuth: requiresAuth}
    },
    {
      path: '/CalculateAlpha',
      name: 'CalculateAlpha',
      component: CalculatorForm,
      props: true,
      meta: { requiresAuth: requiresAuth}
    },
    {
      path: '/',
      // path: '/property-grid',
      name: 'PropertyGrid',
      component: RealEstateGrid,
      props: true,
      meta: { requiresAuth: requiresAuth}
    }
  ]
});

router.beforeEach((to, from, next) => {
  next();
  if (requiresAuth && to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});



export { router, store};
