
export default {
    source : [
        { id: 'DuProprio', name: 'DuProprio' },
        { id: 'Centris', name: 'Centris' },
        { id: 'Other', name: 'Other' }
      ],
    build_style : [
        { id: 'Divided' , name: 'Divided' },
        { id: 'Detached' , name: 'Detached' },
        { id: 'N/S', name: 'N/S' },
        { id: 'Other' , name: 'Other' },
        { id: 'Undivided' , name: 'Undivided' },
        { id: 'Semi-detached', name: 'Semi-detached' },
        { id: 'Attached' , name: 'Attached' }
      ],
    is_pool_heated : [
        { id: 'N/A', name: 'N/A'   },
        { id: 'True', name: 'True'  },
        { id: 'False', name: 'False' }
      ],
    stove : [
        { id: 'None' , name: 'None'       },
        { id: 'Wood Stove', name: 'Wood Stove' },
        { id: 'Gas Stove', name: 'Gas Stove'  },
        { id: 'Oil Stove', name: 'Oil Stove'  }
      ],
    pool_location : [
        { id: 'N/A',     name: 'N/A' },
        { id: 'N/S',     name: 'N/S' },
        { id: 'Indoor',  name: 'Indoor' },
        { id: 'Both',    name: 'Both' },
        { id: 'Outdoor', name: 'Outdoor' }
      ],
    pool_ground_level : [
        { id: 'N/A',          name: 'N/A'            },
        { id: 'Inground',     name: 'Inground'       },
        { id: 'N/S',          name: 'N/S'            },
        { id: 'Above-Ground', name: 'Above-Ground'   },
        { id: 'Both',         name: 'Both'           }
      ],
    age_status : [
        { id: 'Normal' , name: 'Normal' },
        { id: 'Under construction', name: 'Under construction' },
        { id: 'New' , name: 'New' },
        { id: 'Century' , name: 'Century' },
        { id: 'Being Converted', name: 'Being Converted' },
        { id: 'Historic' , name: 'Historic' },
      ],
    parking_type : [
        { id:  'Driveway', name: 'Driveway'             },
        { id:  'None', name: 'None'                },
        { id: 'Driveway Garage', name: 'Driveway Garage'       },
        { id:  'Garage', name: 'Garage'                },
        { id: 'Driveway Double drive', name: 'Driveway Double drive' },
        { id: 'Double drive', name: 'Double drive'             },
        { id: 'Double drive Garage', name: 'Double drive Garage'             },
        { id: 'Driveway Double drive Garage' , name: 'Driveway Double drive Garage'             },
        { id: 'Carport', name: 'Carport'            },
      ],
    property_type : [
        { id: 'Condo' , name: 'Condo' },
        { id: 'House' , name: 'House' },
        { id: 'Triplex' , name: 'Triplex' },
        { id: 'Quadruplex' , name: 'Quadruplex' },
        { id: 'Duplex' , name: 'Duplex' },
        { id: 'N/S', name: 'N/S' },
        { id: 'Lot' , name: 'Lot' },
        { id: 'Quintuplex' , name: 'Quintuplex' },
        { id: 'Multiplex' , name: 'Multiplex' },
        { id:  'Commercial' , name: 'Commercial' }
    ],
    montreal_neighborhood : [
      {name: 'Pierrefonds', id: 'Pierrefonds'},
      {name: 'Mercier', id: 'Mercier'},
      {name: 'Mount Royal', id: 'Mount Royal'},
      {name: 'Downtown', id: 'Downtown'},
      {name: 'Villeray', id: 'Villeray'},
      {name: 'Rosemont', id: 'Rosemont'},
      {name: 'Griffintown', id: 'Griffintown'},
      {name: 'LaSalle', id: 'LaSalle'},
      {name: 'Pointe-Claire', id: 'Pointe-Claire'},
      {name: 'Pointe-aux-Trembles', id: 'Pointe-aux-Trembles'},
      {name: 'Ahuntsic', id: 'Ahuntsic'},
      {name: 'Anjou', id: 'Anjou'},
      {name: 'Cote-des-Neiges', id: 'Cote-des-Neiges'},
      {name: 'Cartierville', id: 'Cartierville'},
      {name: 'Saint-Laurent', id: 'Saint-Laurent'},
      {name: 'Saint-Leonard', id: 'Saint-Leonard'},
      {name: 'Notre-Dame-de-Grace', id: 'Notre-Dame-de-Grace'},
      {name: 'Riviere-des-Prairies', id: 'Riviere-des-Prairies'},
      {name: 'Outremont', id: 'Outremont'},
      {name: 'Verdun', id: 'Verdun'},
      {name: 'Montreal-Nord', id: 'Montreal-Nord'},
      {name: 'N/S', id: 'N/S'},
      {name: 'Longueuil', id: 'Longueuil'},
      {name: "L'Ile-Des-Soeurs", id: "L'Ile-Des-Soeurs"},
      {name: 'Old Montreal', id: 'Old Montreal'},
      {name: 'Mile-End', id: 'Mile-End'},
      {name: 'Maisonneuve', id: 'Maisonneuve'},
      {name: 'Lachine', id: 'Lachine'},
      {name: "L'Ile-Bizard", id: "L'Ile-Bizard"},
      {name: 'Saint-Michel', id: 'Saint-Michel'},
      {name: 'Parc-Extension', id: 'Parc-Extension'},
      {name: 'Montreal East', id: 'Montreal East'},
      {name: 'Saint-Henri', id: 'Saint-Henri'},
      {name: 'Westmount', id: 'Westmount'},
      {name: 'Pointe-Saint-Charles', id: 'Pointe-Saint-Charles'},
      {name: 'Sainte-Genevieve', id: 'Sainte-Genevieve'},
      {name: 'Hochelaga', id: 'Hochelaga'},
      {name: 'Plateau Mont-Royal', id: 'Plateau Mont-Royal'},
      {name: 'Cote-St-Luc', id:'Cote-St-Luc'}
     ],
    toronto_neighborhood: [
      
  {name: "Agincourt North", id :"Agincourt North"},
  {name: "Agincourt South-Malvern West", id :"Agincourt South-Malvern West"},
  {name: "Alderwood", id :"Alderwood"},
  {name: "Annex", id :"Annex"},
  {name: "Avondale", id :"Avondale"},
  {name: "Banbury-Don Mills", id :"Banbury-Don Mills"},
  {name: "Bathurst Manor", id :"Bathurst Manor"},
  {name: "Bay-Cloverhill", id :"Bay-Cloverhill"},
  {name: "Bayview Village", id :"Bayview Village"},
  {name: "Bayview Woods-Steeles", id :"Bayview Woods-Steeles"},
  {name: "Bedford Park-Nortown", id :"Bedford Park-Nortown"},
  {name: "Beechborough-Greenbrook", id :"Beechborough-Greenbrook"},
  {name: "Bendale South", id :"Bendale South"},
  {name: "Bendale-Glen Andrew", id :"Bendale-Glen Andrew"},
  {name: "Birchcliffe-Cliffside", id :"Birchcliffe-Cliffside"},
  {name: "Black Creek", id :"Black Creek"},
  {name: "Blake-Jones", id :"Blake-Jones"},
  {name: "Briar Hill-Belgravia", id :"Briar Hill-Belgravia"},
  {name: "Bridle Path-Sunnybrook-York Mills", id :"Bridle Path-Sunnybrook-York Mills"},
  {name: "Broadview North", id :"Broadview North"},
  {name: "Brookhaven-Amesbury", id :"Brookhaven-Amesbury"},
  {name: "Cabbagetown-South St.James Town", id :"Cabbagetown-South St.James Town"},
  {name: "Caledonia-Fairbank", id :"Caledonia-Fairbank"},
  {name: "Casa Loma", id :"Casa Loma"},
  {name: "Centennial Scarborough", id :"Centennial Scarborough"},
  {name: "Church-Wellesley", id :"Church-Wellesley"},
  {name: "Clairlea-Birchmount", id :"Clairlea-Birchmount"},
  {name: "Clanton Park", id :"Clanton Park"},
  {name: "Cliffcrest", id :"Cliffcrest"},
  {name: "Corso Italia-Davenport", id :"Corso Italia-Davenport"},
  {name: "Danforth", id :"Danforth"},
  {name: "Danforth-East York", id :"Danforth-East York"},
  {name: "Don Valley Village", id :"Don Valley Village"},
  {name: "Dorset Park", id :"Dorset Park"},
  {name: "Dovercourt Village", id :"Dovercourt Village"},
  {name: "Downsview", id :"Downsview"},
  {name: "Downtown Yonge East", id :"Downtown Yonge East"},
  {name: "Dufferin Grove", id :"Dufferin Grove"},
  {name: "East End-Danforth", id :"East End-Danforth"},
  {name: "East L'Amoreaux", id :"East L'Amoreaux"},
  {name: "East Willowdale", id :"East Willowdale"},
  {name: "Edenbridge-Humber Valley", id :"Edenbridge-Humber Valley"},
  {name: "Eglinton East", id :"Eglinton East"},
  {name: "Elms-Old Rexdale", id :"Elms-Old Rexdale"},
  {name: "Englemount-Lawrence", id :"Englemount-Lawrence"},
  {name: "Eringate-Centennial-West Deane", id :"Eringate-Centennial-West Deane"},
  {name: "Etobicoke City Centre", id :"Etobicoke City Centre"},
  {name: "Etobicoke West Mall", id :"Etobicoke West Mall"},
  {name: "Fenside-Parkwoods", id :"Fenside-Parkwoods"},
  {name: "Flemingdon Park", id :"Flemingdon Park"},
  {name: "Forest Hill North", id :"Forest Hill North"},
  {name: "Forest Hill South", id :"Forest Hill South"},
  {name: "Fort York-Liberty Village", id :"Fort York-Liberty Village"},
  {name: "Glenfield-Jane Heights", id :"Glenfield-Jane Heights"},
  {name: "Golfdale-Cedarbrae-Woburn", id :"Golfdale-Cedarbrae-Woburn"},
  {name: "Greenwood-Coxwell", id :"Greenwood-Coxwell"},
  {name: "Guildwood", id :"Guildwood"},
  {name: "Harbourfront-CityPlace", id :"Harbourfront-CityPlace"},
  {name: "Henry Farm", id :"Henry Farm"},
  {name: "High Park North", id :"High Park North"},
  {name: "High Park-Swansea", id :"High Park-Swansea"},
  {name: "Highland Creek", id :"Highland Creek"},
  {name: "Hillcrest Village", id :"Hillcrest Village"},
  {name: "Humber Bay Shores", id :"Humber Bay Shores"},
  {name: "Humber Heights-Westmount", id :"Humber Heights-Westmount"},
  {name: "Humber Summit", id :"Humber Summit"},
  {name: "Humbermede", id :"Humbermede"},
  {name: "Humewood-Cedarvale", id :"Humewood-Cedarvale"},
  {name: "Ionview", id :"Ionview"},
  {name: "Islington", id :"Islington"},
  {name: "Junction Area", id :"Junction Area"},
  {name: "Junction-Wallace Emerson", id :"Junction-Wallace Emerson"},
  {name: "Keelesdale-Eglinton West", id :"Keelesdale-Eglinton West"},
  {name: "Kennedy Park", id :"Kennedy Park"},
  {name: "Kensington-Chinatown", id :"Kensington-Chinatown"},
  {name: "Kingsview Village-The Westway", id :"Kingsview Village-The Westway"},
  {name: "Kingsway South", id :"Kingsway South"},
  {name: "Lambton Baby Point", id :"Lambton Baby Point"},
  {name: "L'Amoreaux West", id :"L'Amoreaux West"},
  {name: "Lansing-Westgate", id :"Lansing-Westgate"},
  {name: "Lawrence Park North", id :"Lawrence Park North"},
  {name: "Lawrence Park South", id :"Lawrence Park South"},
  {name: "Leaside-Bennington", id :"Leaside-Bennington"},
  {name: "Little Portugal", id :"Little Portugal"},
  {name: "Long Branch", id :"Long Branch"},
  {name: "Malvern East", id :"Malvern East"},
  {name: "Malvern West", id :"Malvern West"},
  {name: "Maple Leaf", id :"Maple Leaf"},
  {name: "Markland Wood", id :"Markland Wood"},
  {name: "Milliken", id :"Milliken"},
  {name: "Mimico-Queensway", id :"Mimico-Queensway"},
  {name: "Morningside", id :"Morningside"},
  {name: "Morningside Heights", id :"Morningside Heights"},
  {name: "Moss Park", id :"Moss Park"},
  {name: "Mount Dennis", id :"Mount Dennis"},
  {name: "Mount Olive-Silverstone-Jamestown", id :"Mount Olive-Silverstone-Jamestown"},
  {name: "Mount Pleasant East", id :"Mount Pleasant East"},
  {name: "New Toronto", id :"New Toronto"},
  {name: "Newtonbrook East", id :"Newtonbrook East"},
  {name: "Newtonbrook West", id :"Newtonbrook West"},
  {name: "North Riverdale", id :"North Riverdale"},
  {name: "North St.James Town", id :"North St.James Town"},
  {name: "North Toronto", id :"North Toronto"},
  {name: "Oakdale-Beverley Heights", id :"Oakdale-Beverley Heights"},
  {name: "Oakridge", id :"Oakridge"},
  {name: "Oakwood Village", id :"Oakwood Village"},
  {name: "O'Connor-Parkview", id :"O'Connor-Parkview"},
  {name: "Old East York", id :"Old East York"},
  {name: "Palmerston-Little Italy", id :"Palmerston-Little Italy"},
  {name: "Parkwoods-O'Connor Hills", id :"Parkwoods-O'Connor Hills"},
  {name: "Pelmo Park-Humberlea", id :"Pelmo Park-Humberlea"},
  {name: "Playter Estates-Danforth", id :"Playter Estates-Danforth"},
  {name: "Pleasant View", id :"Pleasant View"},
  {name: "Princess-Rosethorn", id :"Princess-Rosethorn"},
  {name: "Regent Park", id :"Regent Park"},
  {name: "Rexdale-Kipling", id :"Rexdale-Kipling"},
  {name: "Rockcliffe-Smythe", id :"Rockcliffe-Smythe"},
  {name: "Roncesvalles", id :"Roncesvalles"},
  {name: "Rosedale-Moore Park", id :"Rosedale-Moore Park"},
  {name: "Runnymede-Bloor West Village", id :"Runnymede-Bloor West Village"},
  {name: "Rustic", id :"Rustic"},
  {name: "Scarborough Village", id :"Scarborough Village"},
  {name: "South Eglinton-Davisville", id :"South Eglinton-Davisville"},
  {name: "South Parkdale", id :"South Parkdale"},
  {name: "South Riverdale", id :"South Riverdale"},
  {name: "St Lawrence-East Bayfront-The Islands", id :"St Lawrence-East Bayfront-The Islands"},
  {name: "St.Andrew-Windfields", id :"St.Andrew-Windfields"},
  {name: "Steeles", id :"Steeles"},
  {name: "Stonegate-Queensway", id :"Stonegate-Queensway"},
  {name: "Tam O'Shanter-Sullivan", id :"Tam O'Shanter-Sullivan"},
  {name: "Taylor-Massey", id :"Taylor-Massey"},
  {name: "The Beaches", id :"The Beaches"},
  {name: "Thistletown-Beaumond Heights", id :"Thistletown-Beaumond Heights"},
  {name: "Thorncliffe Park", id :"Thorncliffe Park"},
  {name: "Trinity-Bellwoods", id :"Trinity-Bellwoods"},
  {name: "University", id :"University"},
  {name: "Victoria Village", id :"Victoria Village"},
  {name: "Wellington Place", id :"Wellington Place"},
  {name: "West Hill", id :"West Hill"},
  {name: "West Humber-Clairville", id :"West Humber-Clairville"},
  {name: "West Queen West", id :"West Queen West"},
  {name: "West Rouge", id :"West Rouge"},
  {name: "Westminster-Branson", id :"Westminster-Branson"},
  {name: "Weston", id :"Weston"},
  {name: "Weston-Pelham Park", id :"Weston-Pelham Park"},
  {name: "Wexford/Maryvale", id :"Wexford/Maryvale"},
  {name: "Willowdale West", id :"Willowdale West"},
  {name: "Willowridge-Martingrove-Richview", id :"Willowridge-Martingrove-Richview"},
  {name: "Woburn North", id :"Woburn North"},
  {name: "Woodbine Corridor", id :"Woodbine Corridor"},
  {name: "Woodbine-Lumsden", id :"Woodbine-Lumsden"},
  {name: "Wychwood", id :"Wychwood"},
  {name: "Yonge-Bay Corridor", id :"Yonge-Bay Corridor"},
  {name: "Yonge-Doris", id :"Yonge-Doris"},
  {name: "Yonge-Eglinton", id :"Yonge-Eglinton"},
  {name: "Yonge-St.Clair", id :"Yonge-St.Clair"},
  {name: "York University Heights", id :"York University Heights"},
  {name: "Yorkdale-Glen Park", id :"Yorkdale-Glen Park"}
    ]
  };