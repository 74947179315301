import Vue from 'vue';
import App from './App.vue';
import { router, store } from './router';
import VueGtag from 'vue-gtag';
import vuetify from "@/plugins/vuetify";
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

Vue.config.productionTip = false;

// Axios default configuration
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://alpha.realestatealpha.ai'; 
// axios.defaults.baseURL = 'https://deploy-rea.onrender.com'; 
// axios.defaults.baseURL = 'http://127.0.0.1:5000'; 

// Axios interceptors for global error handling
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // store.commit('setAuth', false);
          // store.commit('setUser', null);
          // router.push('/login');
          break;
        case 403:
          Vue.prototype.$vuetify.dialog.error('You do not have permission to perform this action.');
          break;
        case 500:
          Vue.prototype.$vuetify.dialog.error('A server error occurred. Please try again later.');
          break;
        default:
          Vue.prototype.$vuetify.dialog.error(error.response.data.message || 'An unexpected error occurred.');
      }
    } else if (error.request) {
      Vue.prototype.$vuetify.dialog.error('No response from server. Please check your network connection.');
    } else {
      Vue.prototype.$vuetify.dialog.error('An error occurred while setting up the request.');
    }
    return Promise.reject(error);
  }
);

Vue.use(VueGtag, {
  config: { id: 'G-Q0DZ6XE5FR' },
}, router);

new Vue({
  vuetify,
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app');