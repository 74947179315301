<template>
  <div class="summary">
    <div :style="{marginTop: '15px'}" class="page-header-mobile"  v-show="!$vuetify.breakpoint.lgAndUp">
      <v-container>
        <v-row no-gutters>
          <v-col col="12" md="7" >
            <h1 class="page-title-mobile">Method Summary</h1>
          </v-col>
          <v-col col="12" md="5" >
            <div class="title-panel-mobile">
              <router-link to="/" class="page-link">Calculator</router-link>
              <router-link to="/howto" class="page-link">How-To</router-link>
            </div>
          </v-col>
         </v-row>
      </v-container>
    </div>
    <div :style="{marginTop: '15px'}" class="page-header"  v-show="$vuetify.breakpoint.lgAndUp">
      <v-container>
        <v-row no-gutters>
          <v-col cols="5" >
            <div class="title-panel-left">
              <router-link to="/" class="page-link">Calculator</router-link>
              <router-link to="/howto" class="page-link">How-To</router-link>
            </div>
          </v-col>
          <v-col cols="7" >
            <h1 class="page-title">Method Summary</h1>
          </v-col>
         </v-row>
      </v-container>
    </div>
    <div class="Introduction">
      <div class="welcome">Real Estate Alpha Calculator</div>
      This paper presents the Real Estate Alpha Calculator, a tool designed to aid real estate investors by providing a quantitative assessment of investment opportunities. By calculating an "alpha" value for properties in Montreal, the tool helps investors evaluate risk-adjusted returns. The calculator leverages machine learning models for price prediction and adapts the Capital Asset Pricing Model (CAPM) to real estate, offering a data-driven approach to streamline investment decision-making. Our results demonstrate its effectiveness in comparing properties and identifying high-potential investments while minimizing systemic risks. <br>
      <a href="https://tortuetortue.github.io/Resume/REA_Calculator_Paper.pdf" target="_blank">Download Paper</a>
    </div>
    <div class="summary">
      <div class="welcome">Summary of our method</div>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <div class="finance-metrics">
              <h2>Alpha (&#945;) in Real Estate</h2>
              <p>
                Alpha (&#945;) is a measure that indicates whether an investment outperforms or underperforms the market. The formula to calculate alpha is:
              </p>
              <p>
                <strong>&#945; = R<sub>i</sub> - [R<sub>f</sub> + &#946;<sub>i</sub>(R<sub>m</sub> - R<sub>f</sub>)]</strong>
              </p>
              Where ...
              <ul>
                <li><strong>R<sub>i</sub></strong>: Actual return of the investment.</li>
                <li><strong>R<sub>f</sub></strong>: Risk-free rate.</li>
                <li><strong>&#946;<sub>i</sub></strong>: Beta of the investment (volatility).</li>
                <li><strong>R<sub>m</sub></strong>: Expected market return.</li>
              </ul>

              <h3>Interpreting &#945;</h3>
              <ul>
                <li><strong>Positive &#945; (&gt; 0)</strong>: Outperforms the market.</li>
                <li><strong>Zero &#945; (= 0)</strong>: Matches the market's performance.</li>
                <li><strong>Negative &#945; (&lt; 0)</strong>: Underperforms the market.</li>
              </ul>
              </div>
            <h3>Real Estate Alpha Formula</h3>
            <p>To calculate alpha for real estate investments, we adapt the formula to account for real estate specifics:</p>
            <p>
              <strong>&#945;<sub>RE</sub> = NOI / Purchase Price - [R<sub>f</sub> + &#946;<sub>C</sub> (<i>f</i><sub>r</sub>(P) / <i>f</i><sub>p</sub>(P) - R<sub>f</sub>)]</strong>
            </p>
            <h3>Regression Models (<i>f</i><sub>r</sub> and <i>f</i><sub>p</sub>)</h3>
            <p class="caption">
            To predict the expected price for rent and the sale of a property, we trained machine learning regression models. The following table shows are they compared to each other.
            </p>
            <br>
            <h4><b>Root Mean Squared Log Error (RMSLE) ↓ Model Benchmarking on Renting and Property Data</b></h4>
            <v-container>
              <v-row no-gutters>
                <!-- <v-col cols="12" md="6">
                  <table>
                    <thead>
                      <tr>
                        <th>Model</th>
                        <th>Renting ↓</th>
                        <th>Property ↓</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>CatBoost</td>
                        <td>0.1381</td>
                        <td>0.2060</td>
                      </tr>
                      <tr>
                        <td>ElasticNet</td>
                        <td>0.2723</td>
                        <td>0.4642</td>
                      </tr>
                      <tr>
                        <td>GBR</td>
                        <td>0.1391</td>
                        <td><strong>0.1851</strong></td>
                      </tr>
                      <tr>
                        <td>Lasso</td>
                        <td>0.3233</td>
                        <td>0.5155</td>
                      </tr>
                      <tr>
                        <td>LightGBM</td>
                        <td>0.1412</td>
                        <td>0.2124</td>
                      </tr>
                      <tr>
                        <td>NGBoost</td>
                        <td>0.2668</td>
                        <td>0.4487</td>
                      </tr>
                      <tr>
                        <td>RandomForest</td>
                        <td><strong>0.1121</strong></td>
                        <td>0.1862</td>
                      </tr>
                      <tr>
                        <td>Ridge</td>
                        <td>0.2629</td>
                        <td>0.4507</td>
                      </tr>
                      <tr>
                        <td>SVR</td>
                        <td>0.1758</td>
                        <td>0.3420</td>
                      </tr>
                      <tr>
                        <td>XGBoost</td>
                        <td>0.2822</td>
                        <td>0.2061</td>
                      </tr>
                    </tbody>
                  </table>
                </v-col> -->
                <v-col cols="12" md="12">
                  <table>
                    <thead>
                      <tr>
                        <th>Model</th>
                        <th>Renting ↓</th>
                        <th>Property ↓</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RandomForest</td>
                        <td><strong>0.1121</strong></td>
                        <td>0.1862</td>
                      </tr>
                      <tr>
                        <td>GBR</td>
                        <td>0.1391</td>
                        <td><strong>0.1851</strong></td>
                      </tr>
                      <tr>
                        <td>CatBoost</td>
                        <td>0.1381</td>
                        <td>0.2060</td>
                      </tr>
                      <tr>
                        <td>LightGBM</td>
                        <td>0.1412</td>
                        <td>0.2124</td>
                      </tr>
                      <tr>
                        <td>XGBoost</td>
                        <td>0.2822</td>
                        <td>0.2061</td>
                      </tr>
                      <tr>
                        <td>NGBoost</td>
                        <td>0.2668</td>
                        <td>0.4487</td>
                      </tr>
                      <tr>
                        <td>Ridge</td>
                        <td>0.2629</td>
                        <td>0.4507</td>
                      </tr>
                      <tr>
                        <td>SVR</td>
                        <td>0.1758</td>
                        <td>0.3420</td>
                      </tr>
                      <tr>
                        <td>ElasticNet</td>
                        <td>0.2723</td>
                        <td>0.4642</td>
                      </tr>
                      <tr>
                        <td>Lasso</td>
                        <td>0.3233</td>
                        <td>0.5155</td>
                      </tr>
                      <tr>
                        <td>FT-Transformer</td>
                        <td>0.1884</td>
                        <td>0.2594</td>
                      </tr>
                      <tr>
                        <td>Tab-Transformer</td>
                        <td>0.2367</td>
                        <td>0.3101</td>
                      </tr>
                      <tr>
                        <td>ResNet</td>
                        <td>0.2059</td>
                        <td>0.3048</td>
                      </tr>
                      <tr>
                        <td>DeepStack<sub>Preds</sub></td>
                        <td>0.1195</td>
                        <td>0.2263</td>
                      </tr>
                      <tr>
                        <td>DeepStack<sub>Logits</sub></td>
                        <td>0.1441</td>
                        <td>0.2344</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3"><strong>Note:</strong> Bold values indicate the best-performing models (lowest RMSLE) for each dataset.</td>
                      </tr>
                    </tfoot>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="11" md="6">
            <div class="finance-metrics">
              <h2>Risk (&#946;)</h2>
              <p>
                Beta (&#946;) measures the volatility of an investment relative to the market. The formula for beta is:
              </p>
              <p>
                <strong>&#946; = Correlation(P, M) * (&#963;<sub>P</sub> / &#963;<sub>M</sub>)</strong>
              </p>
              Where ...
              <ul>
                <li><strong>P</strong>: Are the prices of the properties similar to the one you are looking at.</li>
                <li><strong>M</strong>: Are the prices of the market.</li>
                <li><strong>&#963;<sub>P</sub></strong>: Standard deviation of P.</li>
                <li><strong>&#963;<sub>M</sub></strong>: Standard deviation of M.</li>
              </ul>
              <h3>Interpreting &#946;</h3>
              <ul>
                <li><strong>&#946; &gt; 1</strong>: More volatile than the market.</li>
                <li><strong>&#946; = 1</strong>: Matches market volatility.</li>
                <li><strong>&#946; &lt; 1</strong>: Less volatile than the market.</li>
              </ul>
            </div>
            <div class="report-section">
              <p> Calculating risk in real estate investment is challenging due to the limited data availability in certain areas and the infrequency of property sales within short timeframes. Our approach addresses these challenges by estimating risk using similar properties within neighborhoods and across the entire city of Montreal. </p>
              
              <h3>Calculation Methodology</h3>
              <p>
                Let <b>A</b> be the property of interest, <b>P<sub>A</sub></b> be a property within the same neighborhood as <b>A</b>, and <b>M</b> be a property within the entire city of Montreal. We define:
              </p>
              <ul>
                <li><b>P<sub>a</sub></b>: The set of all properties <b>P</b> with features similar to <b>A</b></li>
                <li><b>M<sub>a</sub></b>: The set of all properties <b>M</b> with features similar to <b>A</b>, excluding neighborhood</li>
              </ul>
              <!-- <p>
                Since the sample sizes of <b>M<sub>A</sub></b> and <b>P<sub>A</sub></b> differ, directly computing the correlation between them is not feasible. To resolve this, we use aligned samples from both distributions, denoted as <b>M<sup>a</sup><sub>a</sub></b> and <b>P<sup>a</sup><sub>a</sub></b>, where the <b>a</b> index refers to the aligned data. The method for aligning these samples is detailed below. As data alignment does not affect the calculation of relative volatility, the standard deviations for both distributions are computed using the full datasets.
              </p> -->
              <p>
                Since the sample sizes of <b>M<sub>A</sub></b> and <b>P<sub>A</sub></b> differ, computing direct correlation is unimplementable. We align the samples, denoted as <b>M<sup>a</sup><sub>A</sub></b> and <b>P<sup>a</sup><sub>A</sub></b>, where <b>a</b> indicates alignment <i>(detailed below)</i>. As alignment doesn't affect relative volatility, standard deviations are computed using the full datasets.
              </p>


              <p>
                The risk <b>β</b> is calculated using the following formula:
              </p>
              <pre>
                β(A, M) = Corr(P<sup>a</sup><sub>A</sub>, M<sup>a</sup><sub>A</sub>) * (std(P<sub>a</sub>) / std(M<sub>a</sub>))
              </pre>
              <br>
              <h4>Aligning mismatching data counts</h4>
              <!-- <p id="aligning">
                Naturally, as we are using similar properties to the asset <b>P<sub>a</sub></b>, the amount of data fitting those criteria is unlikely to align with the entire market dataset. To address the discrepancy in the number of price points between <b>P<sub>a</sub></b> and <b>M<sub>a</sub></b>, we developed a temporal grouping technique. Below is a simplified explanation:
              </p>
              <ol>
                <li>Group prices for both <b>P<sub>a</sub></b> and <b>M<sub>a</sub></b> by week (since real estate prices are relatively more stable than public market prices, the week is considered a good timeframe for measuring "instant price").</li>
                <li>Pair matching <b>P<sub>a</sub></b> and <b>M<sub>a</sub></b> groups by week, discarding the remaining groups.</li>
                <li>For each group, calculate the median (instead of the mean to avoid sensitivity to extremes).</li>
              </ol>
              <p>
                After this, we are left with aligned samples of <b>P<sub>a</sub></b> and <b>M<sub>a</sub></b>, ready for correlation calculation.
              </p> -->
              <p id="aligning">
                Since similar properties to <b>P<sub>a</sub></b> may not match the market dataset, we developed a temporal grouping method to address this data mismatch:
              </p>
              <ol>
                <li>Group prices for both <b>P<sub>a</sub></b> and <b>M<sub>a</sub></b> by week <i>(RE prices are relatively stable on this timeframe)</i></li>
                <li>Pair matching weekly groups of <b>P<sub>a</sub></b> and <b>M<sub>a</sub></b>, discarding unmatched groups.</li>
                <li>Calculate the median for each group to minimize impact from extreme values.</li>
              </ol>
              <p>
                This process aligns <b>P<sub>a</sub></b> and <b>M<sub>a</sub></b> samples for correlation analysis.
              </p>


              <h4>Risk Normalization</h4>
              <p>
                Real estate data leads to a lower-risk investment compared to the stock market. To better leverage this, we compute a <b>β</b> relative to the Montreal market, calculating <b>β</b> for each unit, grouped by neighborhood and property type.
              </p>

              <p>
                We normalize the <b>β<sub>a</sub></b> of interest using the maximum <b>β<sub>max</sub></b> and minimum <b>β<sub>min</sub></b> values found in our dataset:
              </p>
              <pre>
                β<sub>norm</sub> = (β<sub>a</sub> - β<sub>min</sub>) / (β<sub>max</sub> - β<sub>min</sub>)
              </pre>
              <p>
                Finally, after normalization, we scale <b>β</b> to the range of <b>-1</b> to <b>2</b> to ensure it conveys the complete spectrum of information relevant to <b>α</b>.
              </p>
            </div>
          </v-col>
          <v-col cols="12">
            
            <div class="report-section">
              <figure>
                <h3>Relative market <b>β</b> by property type and neighborhood</h3>
                <p class="caption">
                  Here we have a vizualization of normalized <b>β</b> for each segment of the market. The red square signifies that the information for that part of the market from our dataset.
                </p>
                <img class="image" :src="this.mktbeta()" alt="Relative market volatility by property type and neighborhood.">
                <!-- <figcaption>Relative market volatility by property type and neighborhood. Red values indicate missing data.</figcaption> -->
              </figure>
              <br>
            </div>
          </v-col>
          <v-col cols="12">
            <h2>Montreal Property Price and Rent statistics by neighborhoods </h2>
            <p class="caption">
              Here we have a map of the different areas in the <a href="https://montreal.ca/en/"  target="_blank"><b>City of Montreal</b></a> with information on average and median <b>Rent</b> and <b>Property Sales Prices</b>. Simply hover over the neighborhood that you like to get that information.
            </p>
            <MontrealMapRent/>
          </v-col>
         </v-row>
      </v-container>
      
    </div>
  </div>
</template>

<script>
import MontrealMapRent from '../components/MontrealMap.vue';

export default {
  name: 'MethodSummary',
  components : {
    MontrealMapRent
  },
  methods: {
    mktbeta() {
      return require('@/assets/images/MarketBeta.svg');
    },
  },
  // created() {
  //   if (!this.$store.state.isAuthenticated) {
  //     this.$router.push('/login');
  //   }
  // }
};
</script>

<style>
.caption a{
    text-decoration: none!important;
    bottom: 0;
    color: #006AC3;
}
.report-section {
  text-align: justify;
}
.title-panel-left {
  text-align: right;
  font-size: 2em;
  border-right: 1px solid #c4c8cc;
}
.page-header {
  min-width: 1070px!important;
}
.page-title {
    letter-spacing: 7px;
    font-weight: lighter;
}
.page-link {
    letter-spacing: 4px; /* Adjust the value as needed */
    margin-top: 40px!important;
    margin-bottom: 10px;
    font-weight: lighter;
    font-size: 22px!important;
    color: #006AC3;
    text-decoration: none!important;
    width: 0;
    margin-left: 20px;
    margin-right: 20px;
}
.page-link:hover{
    text-decoration: underline!important;
}

.page-link a{
    text-decoration: none!important;
    bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}
.finance-metrics {
  /* font-family: Arial, sans-serif; */
  padding: 20px;
  max-width: 800px;
  text-align: justify!important;
}

.summary h2, h3 {
  text-align: justify!important;
  color: #2c3e50;
}
.summary p, ul {
  margin: 10px 0;
}
.summary ul {
  list-style-type: None;
  padding-left: 20px;
}
.summary strong {
  color: #024e8c;
}
.image {
  width: 75%;
}
.summary ol {
  margin-left: 20px;
}

.summary pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-left: 5px solid #333;
  overflow-x: auto;
}

.summary figure {
  text-align: center;
  margin-top: 20px;
}

.summary figcaption {
  margin-top: 10px;
  font-style: italic;
}

.summary img {
  max-width: 100%;
  height: auto;
}

.caption {
  text-align: justify!important;
  font-size: 18px;
}

.summary {
  margin-right: 4%;
  margin-left: 4%;
}
.summary table {
    width: 95%;
    /* margin-left: 3%; */
    margin-right: 5%;
    border-collapse: separate;
    border-spacing: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    margin-bottom: 30px;
    box-shadow: 0 0 30px rgba(0, 106, 195, 0.1);
    border-radius: 10px;
    overflow: hidden;
}
thead {
    background: linear-gradient(135deg, #006AC3, #024e8c);
}

th, td {
    padding: 15px 20px;
    text-align: left;
}

th {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

td {
    border-bottom: 1px solid #e0e0e0;
    font-size: 15px;
    transition: all 0.3s ease;
}

tr:last-child td {
    border-bottom: none;
}

tbody tr {
    transition: all 0.3s ease;
}

tbody tr:hover {
    background-color: rgba(0, 106, 195, 0.05);
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0, 106, 195, 0.1);
}

/* Style for metric names */
td:first-child {
    font-weight: 600;
    color: #006AC3;
}

/* Style for metric values */
td:not(:first-child) {
    text-align: right;
    font-family: 'Courier New', Courier, monospace;
}

/* Fancy hover effect for cells */
td:hover {
    background-color: rgba(2, 78, 140, 0.1);
    color: #024e8c;
}

/* Responsive design */
@media screen and (max-width: 768px) {
    table {
        font-size: 14px;
    }
    
    th, td {
        padding: 10px 15px;
    }
}

/* Zebra striping */
tbody tr:nth-child(even) {
    background-color: #f8f8f8;
}

/* Add some fancy data visualization bars */
.data-bar {
    display: inline-block;
    height: 8px;
    background-color: #006AC3;
    margin-right: 5px;
    vertical-align: middle;
}
.title-panel-mobile {
  /* display: flex;
  gap: 20px; */
  text-align: center;
  font-size: 2em;
  border-top: 1px solid #c4c8cc;
  padding-top: 25px;
  /* padding-left: 15px; */
  /* position: absolute;
  bottom: 0; */
}
.page-title-mobile {
    letter-spacing: 7px;
    font-weight: lighter;
    font-size: 32px;
    margin-bottom: 20px;
}
</style>
